/*******************/
/***** REPORTS *****/
/*******************/
.report-schedule-name {
    margin:0px 0px 0px 0px;
}

.report-name {
    margin:0px 0px 30px 0px;
}

.report-container {
    font-family: "Nunito", sans-serif;
	background-color: #f5f7fa;
	padding:20px;
}

.reports-main-container {
    position:relative;
    margin:30px 0px 0px 0px;
    min-width: 400px;
}

.reports-main-inner-container {
    position: absolute;
    width: 100%;
    min-width: fit-content;
    padding: 10px 20px 20px;
    margin-bottom: 50px;
    background-color: rgb(255, 255, 255);
    border: 2px solid #efefef;
    z-index: 99;
}

.reports-search-bar-container {
	position: relative;
    display: grid;
    min-width: 250px;
    max-width: 400px;
    grid-template-columns: 1fr 40px;
}

.reports-search-bar {
	height: 40px;
    border-color: #e5e5e5;
    border-radius: 20px;
    padding: 0px 0px 0px 43px;
    font-size: 14px;
}

.reports-search-bar:focus {
	border-color: #ddd !important;
}

.reports-search-bar-icon {
	position: absolute;
    top: 12px;
    left: 15px;
    color: #dfdfdf;
    transition: 0.3s;
}

.reports-search-bar-container .reports-search-bar:focus + .reports-search-bar-icon {
	color:#2980b9;
}

/************************************/
/****** STUDENT REQUESTS REPORT *****/
/************************************/
.student-requests-course-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
}

.student-requests-current-requests-container, .student-requests-add-request-container {
    background-color: #fff;
    padding:20px;
    height: fit-content;
}

.student-requests-subheader-container {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin:0px 0px 15px 0px;
}

.student-requests-request-line {
    display: grid;
    grid-template-columns: auto 1fr;
    padding:5px 10px 5px 12px;
    align-items: center;
    font-size: 14px;
    column-gap: 30px;
}

.student-requests-request-line:nth-child(odd) {
    background-color: #efefef;
}

.student-requests-confirm-btn-container {
    display: grid;
    row-gap: 5px;
    align-items: center;
}

.student-requests-add-new-student-btn {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;
}

.student-requests-report-search-result:nth-child(odd) {
    background-color: #efefef;
    padding:5px 7px;
}

.student-requests-report-already-requesting-container {
    display: grid;
    grid-template-columns: 70px 1fr;
    align-items: center;
}

.student-requests-grade-row {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;
}

.student-requests-add-by-grade-student-list {
    background-color: #efefef;
    padding:10px;
    height:200px;
    overflow-y: auto;
    margin:10px 0px 10px 0px;
}

#student-requests-filter-students-with-request {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    margin: 15px 0px 20px 0px;
}

/*****************************/
/****** CONFLICTS REPORT *****/
/*****************************/
.conflicts-tab {
    display: grid;
    grid-template-columns: 1fr auto;
    position:absolute;
    left:0px;
    top:-37px;
    width:150px;
    padding:10px;
    text-align: center;
    background-color: #fff;
    border:1px solid #efefef;
    cursor: pointer;
}

.warnings-tab {
    display: grid;
    grid-template-columns: 1fr auto;
    position: absolute;
    left: 150px;
    top: -37px;
    width: 150px;
    padding: 10px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    border:1px solid #efefef;
    cursor: pointer;
}

.ignored-conflicts-tab {
    display: grid;
    grid-template-columns: 1fr auto;
    position:absolute;
    left:300px;
    top:-37px;
    width:180px;
    padding:10px;
    text-align: center;
    background-color: #fff;
    border:1px solid #efefef;
    cursor: pointer;
}

.ignored-warnings-tab {
    display: grid;
    grid-template-columns: 1fr auto;
    position: absolute;
    left: 480px;
    top: -37px;
    width: 180px;
    padding: 10px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    border:1px solid #efefef;
    cursor: pointer;
}

.conflicts-selected-tab {
    top:-41px;
    border:2px solid #efefef;
    border-bottom:none;
    z-index: 999;
}

.conflicts-type-container {
    margin:0px 0px 30px 0px;
}

.conflict-row {
    max-width: 450px;
    margin: 0px 0px 15px 0px;
    font-size: 14px;
    border: 2px solid #efefef;
    border-radius: 0px 10px 10px 0px;
}

.conflict-inner-row {
    padding: 15px 30px 15px 30px;
}

.conflict-heading {
    margin:0px 0px 12px 0px;
}

.conflict-info-container {
    display: grid;
    row-gap: 10px;
}

.conflict-info-row {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap:15px;
    align-items: center;
}

.conflict-info-row-icon {
    font-size: 13px;
    padding:4px;
    border-radius: 5px;
}

.conflict-ignore-conflict {
    display: grid;
    grid-template-columns: 1fr 150px;
    border-top: 1px solid rgb(239, 239, 239);
    justify-items: center;
}

.conflict-ignore-conflict-btn {
    width: 100%;
    border-left: 1px solid #efefef;
    text-align: center;
    padding: 10px 0px;
    font-size:12px;
    font-weight: bold;
    color: #34495e;
    cursor: pointer;
}

.conflict-ignore-conflict-btn:hover {
    background-color: #efefef;
    color:#2c3e50;
}

/****************************/
/***** SCHEDULE SUMMARY *****/
/****************************/
.schedule-summary-name-row {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.schedule-summary-course-row {
    display: grid;
    grid-template-columns: 150px 200px 1fr 1fr 1fr 1fr 1fr 40px;
    justify-items: center;
    padding:10px 5px 10px 10px;
    margin: 0px 0px 1px 0px;
    align-items: center;
}

.schedule-summary-course-name {
    justify-self: start;
}

.schedule-summary-toggle-arrow {
    padding: 4px 0px 0px 0px;
    cursor: pointer;
}

.schedule-summary-more-info-container {
    padding: 10px 20px 20px;
    border: 2px solid rgb(239, 239, 239);
    margin: 10px 0px 50px;
}

.schedule-summary-more-info-data-container {
    margin:0px 0px 15px 0px;
}

.schedule-summary-section-row {
    display: grid;
    grid-template-columns: 1fr 100px;
    /* align-items: center; */
}

.schedule-summary-section-row:nth-child(even) {
    background-color: #efefef;
}

.schedule-summary-section-inner-row {
    display: grid;
    grid-template-columns: 100px 80px 2fr 2fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    align-items: start;
    padding:15px;
    font-size: 14px;
    justify-items: left;
}

.schedule-summary-more-details {
    padding:15px 0px;
}

.schedule-summary-section-center {
    justify-self: center;
}

.schedule-summary-more-info-data-row {
    display: grid;
    column-gap: 20px;
    align-items: center;
}

.schedule-summary-more-info-heading {
    text-align: center;
    font-size: 12px;
}

.schedule-summary-section-indicator-row {
   text-align: center;
   margin: 5px 0px 0px 0px;
}

.schedule-summary-section-indicator {
    display: inline-block;
    margin:0px 3px 0px 3px;
    height: 8px;
    width: 8px;
    border-radius: 12px;
}

.schedule-summary-quarters-row {
    width: 100%;
}

.schedule-summary-quarter-day-available {
    background-color: #7cc47f;
    color:#fafafa;
}

.schedule-summary-student-quarter-day {
	width: 100%;
    text-align: center;
}

.schedule-summary-student-quarter-day:not(:last-child) {
	border-right: 1px solid #efefef;
}

.schedule-summary-student-quarter-day:last-child > .schedule-summary-student-quarter-day-heading {
	border-radius: 0px 3px 0px 0px;
}

.schedule-summary-student-quarter-day:first-child > .schedule-summary-student-quarter-day-heading {
	border-radius: 3px 0px 0px 0px;
}

.schedule-summary-unmatched-students-container {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   align-items: center;
}

.schedule-summary-unmatched-student {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    align-items: center;
    column-gap: 20px;
    height:40px;
    padding: 0px 25px 0px 25px;
    border-right:1px solid #efefef;
}

.schedule-summary-unmatched-student:hover {
    background-color: #efefef;
}

/****************************/
/****** CONFLICT MATRIX *****/
/****************************/
.conflict-matrix-course-container {
    border-bottom:1px solid #efefef;
    padding:0px 0px 50px 0px;
}

.conflict-matrix-no-data {
    padding:20px;
    background-color: #efefef;
    color:#a9a9a9;
}

.conflict-matrix-course-inner-container {
    -webkit-columns: 3 200px;  /* Chrome, Safari, Opera */
    -moz-columns: 3 200px;     /* Firefox */
    columns: 3 200px;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    -webkit-column-rule: 1px dotted #efefef;
    -moz-column-rule: 1px dotted #efefef;
    column-rule: 1px dotted #efefef;
}

.conflict-matrix-conflict-header {
    display: grid;
    grid-template-columns: 1fr 100px;
    font-weight: bold;
    font-size: 16px;
    padding: 0px 0px 5px;
    align-items: center;
    text-decoration: underline;
}

.conflict-matrix-conflict-line {
    display: grid;
    grid-template-columns: 1fr auto;
    font-size: 14px;
    padding: 5px 0px 5px 0px;
    border-bottom: 1px dotted #efefef;
}

/*****************************/
/***** SECTION BALANCING *****/
/*****************************/
.section-balancing-summary-row-box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding:3px 0px 3px 0px;
    align-items: center;
    justify-items: center;
}

.section-balancing-section {
    margin:0px 0px 15px 0px !important;
}

.section-balancing-subsection-container {
    padding:0px 0px 5px 0px;
    border-bottom:1px solid #efefef;
}

.section-balancing-total-students {
    font-size: 12px;
    padding:5px 0px 0px 0px;
    text-align: left;
}

.section-balancing-summary-quarter-column {
    display: grid;
    width: 100%;
    text-align: center;
    row-gap: 10px;
}

.student-summary-statistics-inner-container > .mb-schedule-row {
    border-bottom: 1px solid #efefef;
}

.section-balancing-title-container {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.section-balancing-btn {
    display: inline-block;
    max-width: 200px;
    font-size: 14px;
}

/***************************/
/***** STUDENT SUMMARY *****/
/***************************/
.student-summary-statistics-container {
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(239, 239, 239);
    margin: 0px 0px 30px;
}

.student-summary-statistics-header {
    padding: 5px 10px;
    font-size: 20px;
}

.student-summary-statistics-inner-container {
    padding: 10px 30px;
}

.student-summary-statistics-row {
    display: grid;
    grid-template-columns: 200px 1fr 1fr 1fr;
    column-gap: 50px;
    padding:10px;
}

.student-summary-header {
    padding: 0px;
    margin: 0px 10px 0px 0px;
}

.student-summary-row-box {
    display: grid;
    grid-template-columns: 50px 1fr;
}

.student-summary-secondary-header {
    margin:50px 0px 10px 0px;
}

.student-summary-student-container {
    border-bottom:1px solid #dddddd;
    padding: 30px 0px 100px;
}

.student-summary-student-request-row {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 20px;
	align-items: center;
	text-align: left;
	padding:10px 10px 9px 10px;
	border:1px solid #efefef;
	border-radius: 8px;
}

.student-summary-request-container {
    padding: 10px;
    max-width: 600px;
}

.student-summary-request-heading {
    font-size: 18px;
    width: 100%;
    padding: 5px 5px 5px 0px;
    margin: 15px 0px 0px;
    text-align: left;
    font-weight: bold;
    border-radius: 10px 10px 0px 0px;
}