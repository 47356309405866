html,body {
  font-family: "Nunito", sans-serif;
  background-color: #f5f7fa;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 100;
  color: #34495e;
}

#root, .App {
	height:100%;
	overflow: hidden;
  padding: 0px 0px 0px 0px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}


/* Essentials */
.container {
  display: table;
  width: 100%;
  height: 100%;
}

.content {
  display: table-row;
  height: 100%;
}

.content-body {
  display: table-cell;
}

div, input, h1, h2, h3, h4, h5, h6, textarea {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

h1, h2 {
	color:#2c3e50;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input {
	width:100%;
	height:32px;
	line-height: 32px;
	border:2px solid #efefef;
	border-radius: 5px;
	padding:0px 10px 0px 10px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="time"]::-webkit-calendar-picker-indicator { 
	background: none !important; 
	display:none !important; 
}

input[type="time"]::-webkit-clear-button {
    display: none !important;
}

input[type="time"]::-ms-clear {
    display: none !important;
}

.datetime-reset-button {
	display: none !important;
}

input:focus, textarea:focus {
  outline: none;
  border: 2px solid #2980b9;
}

input:disabled {
  background-color: #efefef !important;
  cursor: not-allowed !important;
}

textarea {
  width: 100%;
  border: 2px solid #ddd;
  min-height: 100px;
  padding:8px;
  resize: none;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.input-label {
	font-size: 12px;
	font-weight: bold;
	font-family: "Nunito", sans-serif;
	color:#a9a9a9;
}

::-webkit-input-placeholder { /* Edge */
  color: #cccccc;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #cccccc;
}

::placeholder {
  color: #cccccc;
}

button
{
    border: none;
    outline:none;
}

button:focus
{
    border: none;
    outline:none;
}

a {
  text-decoration: none;
  color: #3498db;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fadeOut {
     opacity:0;
     height:0;
     margin:0 !important;
     padding:0 !important;
     transition: opacity 0.3s;
     transition: height 0.3s;
     transition: margin 0.3s;
     transition: padding 0.3s;
	 pointer-events: none;
}

.fadeIn {
     opacity:1;
     transition: opacity 0.3s 0.3s;

}

@keyframes fadeOutRight {
	from {
		opacity: 1;
	}
	
	to {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}

.hide {
	display: none !important;
}

.clear {
  clear: both;
}

.capitalize {
  text-transform: capitalize !important;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.align-right {
  margin-right:0 !important;
  margin-left:auto !important;
}

.align-center {
  margin:auto !important;
}

-main-background {
  background-color: #f5f7fa !important;
}

.red {
  background-color: #c0392b !important;
  color: #fff !important;
}

.bright_red,
.bright-red {
  background-color: #e74c3c !important;
  color: #fff !important;
}

.orange {
  background-color: #e67e22 !important;
  color: #fff !important;
}

.dark-orange {
  background-color: #d35400 !important;
  color: #fff !important;
}

.yellow {
  background-color: #f39c12 !important;
  color: #fff !important;
}

.bright_yellow,
.bright-yellow {
  background-color: #f1c40f !important;
  color: #fff !important;
}

.green {
  background-color: #27ae60 !important;
  color: #fff !important;
}

.bright_green,
.bright-green {
  background-color: #2ecc71 !important;
  color: #fff !important;
}

.turquoise {
  background-color: #16a085 !important;
  color: #fff !important;
}

.blue {
  background-color: #2980b9 !important;
  color: #fff !important;
}

.bright-blue {
  background-color: #3498db !important;
  color: #fff !important;
}

.dark_blue,
.dark-blue {
  background-color: #2c3e50 !important;
  color: #fff !important;
}

.dark_blue_grey,
.dark_blue_gray,
.dark-blue-grey,
.dark-blue-gray {
  background-color: #34495e !important;
  color: #fff !important;
}

.pastel_dark_blue_grey,
.pastel_dark_blue_gray,
.pastel-dark-blue-grey,
.pastel-dark-blue-gray {
  background-color: #678aae !important;
  color: #fff !important;
}

.purple {
  background-color: #8e44ad !important;
  color: #fff !important;
}

.bright-purple {
  background-color: #9b59b6 !important;
  color: #fff !important;
}

.pastel-light-green {
	background-color: #55efc4 !important;
	color:#fff !important;
}

.pastel-green {
	background-color: #00b894 !important;
	color:#fff !important;
}

.pastel-light-turquoise {
	background-color: #81ecec !important;
	color:#fff !important;
}

.pastel-turquoise {
	background-color: #00cec9 !important;
	color:#fff !important;
}

.pastel-light-blue {
	background-color: #74b9ff !important;
	color:#fff !important;
}

.pastel-blue {
	background-color: #0984e3 !important;
	color:#fff !important;
}

.pastel-light-purple {
	background-color: #a29bfe !important;
	color:#fff !important;
}

.dark-purple {
  background-color: #474CCB !important;
  color: #fff !important;
}

.dark-purple-gray, .dark-purple-grey {
  background-color: #56588b !important;
  color: #fff !important;
}

.pastel-dark-purple {
	background-color: #6c5ce7 !important;
	color:#fff !important;
}

.pastel-light-yellow {
	background-color: #ffeaa7 !important;
	color:#fff !important;
}

.pastel-yellow {
	background-color: #fdcb6e !important;
	color:#fff !important;
}

.pastel-light-orange {
	background-color: #fab1a0 !important;
	color:#fff !important;
}

.pastel-orange {
	background-color: #e17055 !important;
	color:#fff !important;
}

.pastel-brown {
  background-color: #BC9E82 !important;
	color: #fff !important;
}

.dark-brown {
  background-color: #695848 !important;
  color:#fff !important;
}

.light_grey,
.light-gray,
.light-grey {
  background-color: #efefef !important;
  color: #999 !important;
}

.grey, .gray {
  background-color: #cad1d2 !important;
}

.dark_grey,
.dark-gray,
.dark-grey {
  background-color: #7f8c8d !important;
  color: #fff !important;
}

.white {
  background-color: #fff !important;
  color: #34495e !important;
}

.gray-text-hover, .grey-text-hover {
	color:#efefef !important;
	cursor: pointer;
}

.gray-text-hover:hover, .grey-text-hover:hover {
	color:#a9a9a9 !important;
}

.highlight {
  background-color: #ccff15;
  padding: 0px 5px 0px 5px;
}

.red-text {
  color: #c0392b !important;
}

.bright-red-text {
  color: #e74c3c !important;
}

.orange-text {
  color: #e67e22 !important;
}

.dark-orange-text {
  color: #d35400 !important;
}

.yellow-text {
  color: #f39c12 !important;
}

.bright-yellow-text {
  color: #f1c40f !important;
}

.green-text {
  color: #27ae60 !important;
}

.bright-green-text {
  color: #2ecc71 !important;
}

.turquoise-text {
  color: #16a085 !important;
}

.blue-text {
  color: #2980b9 !important;
}

.bright-blue-text {
  color: #3498db !important;
}

.dark-blue-text,
.dark_blue-text {
  color: #2c3e50 !important;
}

.dark-blue-gray-text {
  color: #34495e !important;
}

.pastel-dark-blue-gray-text {
  color: #678aae !important;
}

.purple-text {
  color: #9b59b6 !important;
}

.pastel-light-green-text {
	color: #55efc4 !important;
}

.pastel-green-text {
	color: #00b894 !important;
}

.pastel-light-turquoise-text {
	color: #81ecec !important;
}

.pastel-turquoise-text {
	color: #00cec9 !important;
}

.pastel-light-blue-text {
	color: #74b9ff !important;
}

.pastel-blue-text {
	color: #0984e3 !important;
}

.pastel-light-purple-text {
	color: #a29bfe !important;
}

.dark-purple-text {
  color: #474CCB !important;
}

.dark-purple-gray-text {
  color: #56588b !important;
}

.pastel-dark-purple-text {
	color: #6c5ce7 !important;
}

.pastel-light-yellow-text {
	color: #ffeaa7 !important;
}

.pastel-yellow-text {
	color: #fdcb6e !important;
}

.pastel-light-orange-text {
	color: #fab1a0 !important;
}

.pastel-orange-text {
	color: #e17055 !important;
}

.pastel-brown-text {
	color: #BC9E82 !important;
}

.dark-brown-text {
  color: #695848 !important;
}

.light-gray-text {
  color: #efefef !important;
}

.gray-text {
  color: #d0d0d0 !important;
}

.dark-gray-text {
  color: #a9a9a9 !important;
}

.white-text {
  color: #fff !important;
}

.extra-extra-large-text {
  font-size: 20px !important;
}

.extra-large-text {
  font-size: 18px !important;
}

.large-text {
  font-size: 16px !important;
}

.medium-text {
	font-size: 14px !important;
}

.small-text {
  font-size: 12px !important;
}

.extra-small-text {
  font-size: 10px !important;
}

.bold-text {
  font-weight: bold;
}

.red_border {
  border: 1px solid #c0392b !important;
}

.bright_red_border, .bright-red-border {
  border: 1px solid #e74c3c !important;
}

.orange_border, .orange-border {
  border: 1px solid #e67e22 !important;
}

.dark_orange_border, .dark-orange-border {
  border: 1px solid #d35400 !important;
}

.yellow_border, .yellow-border {
  border: 1px solid #f39c12 !important;
}

.bright_yellow_border, .bright-yellow-border {
  border: 1px solid #f1c40f !important;
}

.pastel_yellow_border, .pastel-yellow-border {
	border: 1px solid #fdcb6e !important;
}

.green_border, .green-border {
  border: 1px solid #27ae60 !important;
}

.bright_green_border, .bright-green-border {
  border: 1px solid #2ecc71 !important;
}

.turquoise_border, .turquoise-border {
  border: 1px solid #16a085 !important;
}

.blue_border, .blue-border {
  border: 1px solid #2980b9 !important;
}

.bright_blue_border, .bright-blue-border {
  border: 1px solid #3498db !important;
}

.dark_blue_border, .dark-blue-border {
  border: 1px solid #2c3e50 !important;
}

.dark_blue_grey_border, .dark-blue-grey-border {
  border: 1px solid #34495e !important;
}

.purple_border, .purple-border {
  border: 1px solid #9b59b6 !important;
}

.dark_purple_border, .dark-purple-border {
  border: 1px solid #474CCB !important;
}

.dark_purple_gray_border,.dark_purple_grey_border, .dark-purple-gray-border, .dark-purple-grey-border {
  border: 1px solid #56588b !important;
}

.pastel-light-purple-border {
  border: 1px solid #a29bfe !important;
}

.light_gray_border, .light-gray-border {
  border: 1px solid #efefef !important;
}

.gray_border, .gray-border {
  border: 1px solid #dfdfdf !important;
}

.left_border_red, .left-border-red {
  border-left: 3px solid #c0392b !important;
}

.left_border_orange, .left-border-orange {
  border-left: 3px solid #e67e22 !important;
}

.left_border_dark_orange, .left-border-dark-orange {
  border-left: 3px solid #d35400 !important;
}

.left_border_yellow, .left-border-yellow {
  border-left: 3px solid #f39c12 !important;
}

.left_border_purple, .left-border-purple {
  border-left: 3px solid #9b59b6 !important;
}

.left_border_blue, .left-border-blue {
  border-left: 3px solid #2980b9 !important;
}

.left_border_dark_blue, .left-border-dark-blue {
  border-left: 3px solid #2c3e50 !important;
}

.left_border_green, .left-border-green {
  border-left: 3px solid #27ae60 !important;
}

.left_border_turquoise, .left-border-turquoise {
  border-left: 3px solid #1abc9c !important;
}

.left-border-pastel-light-green {
	border-left: 3px solid #55efc4 !important;
}

.left-border-pastel-green {
	border-left: 3px solid #00b894 !important;
}

.left-border-pastel-light-turquoise {
	border-left: 3px solid #81ecec !important;
}

.left-border-pastel-turquoise {
	border-left: 3px solid #00cec9 !important;
}

.left-border-pastel-light-blue {
	border-left: 3px solid #74b9ff !important;
}

.left-border-pastel-blue {
	border-left: 3px solid #0984e3 !important;
}

.left-border-pastel-light-purple {
	border-left: 3px solid #a29bfe !important;
}

.left-border-dark-purple {
  border-left:3px solid #474CCB !important;
}

.left-border-dark-purple-grey, .left-border-dark-purple-gray {
  border-left:3px solid #56588b !important;
}

.left-border-pastel-dark-purple {
	border-left: 3px solid #6c5ce7 !important;
}

.left-border-pastel-light-yellow {
	border-left: 3px solid #ffeaa7 !important;
}

.left-border-pastel-yellow {
	border-left: 3px solid #fdcb6e !important;
}

.left-border-pastel-light-orange {
	border-left: 3px solid #fab1a0 !important;
}

.left-border-pastel-orange {
	border-left: 3px solid #e17055 !important;
}

.left-border-pastel-brown {
	border-left: 3px solid #BC9E82 !important;
}

.left_border_red_thick, .left-border-red-thick {
  border-left: 5px solid #c0392b !important;
}

.left_border_orange_thick, .left-border-orange-thick {
  border-left: 5px solid #e67e22 !important;
}

.left_border_dark_orange_thick, .left-border-dark-orange-thick {
  border-left: 5px solid #d35400 !important;
}

.left_border_yellow_thick, .left-border-yellow-thick {
  border-left: 5px solid #f39c12 !important;
}

.left_border_purple_thick, .left-border-purple-thick {
  border-left: 5px solid #9b59b6 !important;
}

.left_border_blue_thick, .left-border-blue-thick {
  border-left: 5px solid #2980b9 !important;
}

.left_border_dark_blue_thick, .left-border-dark-blue-thick {
  border-left: 5px solid #2c3e50 !important;
}

.left_border_green_thick, .left-border-green-thick {
  border-left: 5px solid #27ae60 !important;
}

.left_border_turquoise_thick, .left-border-turquoise-thick {
  border-left: 5px solid #1abc9c !important;
}

.left-border-pastel-light-green-thick {
	border-left: 5px solid #55efc4 !important;
}

.left-border-pastel-green-thick {
	border-left: 5px solid #00b894 !important;
}

.left-border-pastel-light-turquoise-thick {
	border-left: 5px solid #81ecec !important;
}

.left-border-pastel-turquoise-thick {
	border-left: 5px solid #00cec9 !important;
}

.left-border-pastel-light-blue-thick {
	border-left: 5px solid #74b9ff !important;
}

.left-border-pastel-blue-thick {
	border-left: 5px solid #0984e3 !important;
}

.left-border-pastel-light-purple-thick {
	border-left: 5px solid #a29bfe !important;
}

.left-border-dark-purple-thick {
  border-left:5px solid #474CCB !important;
}

.left-border-dark-purple-grey-thick, .left-border-dark-purple-gray-thick {
  border-left:5px solid #56588b !important;
}

.left-border-pastel-dark-purple-thick {
	border-left: 5px solid #6c5ce7 !important;
}

.left-border-pastel-light-yellow-thick {
	border-left: 5px solid #ffeaa7 !important;
}

.left-border-pastel-yellow-thick {
	border-left: 5px solid #fdcb6e !important;
}

.left-border-pastel-light-orange-thick {
	border-left: 5px solid #fab1a0 !important;
}

.left-border-pastel-orange-thick {
	border-left: 5px solid #e17055 !important;
}

.left-border-pastel-brown-thick {
	border-left: 5px solid #BC9E82 !important;
}

.no-border {
  border: none !important;
  outline: none !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.opaque {
  opacity: .5;
}

.opaque-link {
	opacity: .5;
	cursor: pointer;
}

.opaque-link:hover {
	opacity: 1;
}

.blue-link {
  width: fit-content;
	color:#2980b9 !important;
	cursor: pointer;
}

.blue-link:hover {
	color:#3498db !important;
}

.dark-blue-link {
  width: fit-content;
	color:#536679 !important;
	cursor: pointer;
}

.dark-blue-link:hover {
	color:#2c3e50 !important;
}

.red-link {
  width: fit-content;
	color: #c0392b !important;
	cursor: pointer;
}

.red-link:hover {
	color:#e74c3c !important;
}

.green-link {
  width: fit-content;
	color: #27ae60 !important;
	cursor: pointer;
}

.green-link:hover {
	color:#2ecc71 !important;
}

.orange-link {
  width: fit-content;
	color:#f39c12 !important;
	cursor: pointer;
}

.orange-link:hover {
	color:#e67e22 !important;
}

.dark-orange-link {
  width: fit-content;
	color:#d35400;
	cursor: pointer;
}

.dark-orange-link:hover {
	color:#e67e22;
}

.turquoise-link {
  width: fit-content;
	color:#16a085 !important;
	cursor: pointer;
}

.turquoise-link:hover {
	color:#1abc9c !important;
}

.purple-link {
  width: fit-content;
	color:#8e44ad !important;
	cursor: pointer;
}

.purple-link:hover {
	color:#9b59b6 !important;
}

.gray-link, .grey-link {
  width: fit-content;
  color: #ddd !important;
  cursor: pointer;
}

.gray-link:hover, .grey-link:hover {
	color:#a9a9a9 !important;
}

.dark-gray-link, .dark-grey-link {
  width: fit-content;
  color: #a9a9a9 !important;
  cursor: pointer;
}

.dark-gray-link:hover, .dark-grey-link:hover {
	color:#9b9b9b !important;
}

.dark-blue-to-red-link {
  width: fit-content;
	color:#34495e !important;
	cursor: pointer;
}

.dark-blue-to-red-link:hover {
	color:#c0392b !important;
}

.gray-to-blue-link {
  width: fit-content;
	color:rgb(218, 218, 218) !important;
	cursor:pointer;
}

.gray-to-blue-link:hover {
	color:#2980b9 !important;
}

.gray-to-dark-blue-link {
  width: fit-content;
	color:rgb(218, 218, 218) !important;
	cursor:pointer;
}

.gray-to-dark-blue-link:hover {
	color:#34495e !important;
}

.gray-to-red-link{
  width: fit-content;
	color:rgb(236, 199, 199) !important;
	cursor:pointer;
}

.gray-to-red-link:hover {
	color:#c0392b !important;
}

.approve-link, .cancel-link {
  width: fit-content;
	color: #ccc !important;
	cursor: pointer;
}

.approve-link:hover {
	color:#27ae60 !important;
}

.cancel-link:hover {
	color:#c0392b !important;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.cursor-pointer {
	cursor:pointer !important;
}

.tooltip-css {
  position: absolute;
  padding: 3px;
  top: 0px;
  left: 100px;
  font-size: 12px;
  background-color: #000;
  color: #fff;
  z-index: 9999;
}

/* Tooltip container */
.tooltip, .tooltip-left {
  position: relative;
	min-width: 0;
}

/* Tooltip text */
.tooltip::after {
	content:attr(data-tooltip-text);
	position: absolute;
	display: block;
	background-color: black;
	padding: 5px 10px 5px 10px;
	color: #fff;
	border-radius: 6px;
	font-size: 12px;
	text-align: center;
	top:0;
	left:0;
	white-space: nowrap;
	transform: scale(0);
	z-index: 9999999;
}

/* Tooltip text */
.tooltip-left::after {
	display: block;
	content:attr(data-tooltip-text);
	position: absolute;
	background-color: black;
	padding: 5px 10px 5px 10px;
	color: #fff;
	border-radius: 6px;
	font-size: 12px;
	text-align: center;
	top:0;
	right:0;
	white-space: nowrap;
	transform: scale(0);
	z-index: 9999999;
}


/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover::after, .tooltip-left:hover::after {
  transform: scale(1);
  top:100%;
}

.center-text {
  text-align: center;
}

.center-grid {
	display: grid;
	height:100%;
	align-items: center;
	justify-items: center;
}

.full-width {
	width: 100%;
}

.header {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #1a2836;
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding: 0px 0px 0px 20px;
  margin: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 200;
  /*background-color: #fff;
    box-shadow: 0 0 5px rgba(110,103,86,.15);*/
}

.header-container {
	position: sticky;
  top:0px;
  left:0px;
	display: grid;
	grid-template-columns: 150px 1fr auto auto auto;
	grid-column-gap: 40px;
	align-items: center;
	width: 100%;
	padding:10px 20px 10px 20px;
	background-color: #fff;
	-webkit-box-shadow: 0 3px 5px #efefef;
	-moz-box-shadow: 0 3px 5px #efefef;
	box-shadow: 0 3px 5px #efefef;
	z-index: 990;
}

.header-notification-container {
	position: relative;
	cursor: pointer;
}

.header-notification-img {
	font-size: 18px;
	margin:2px 0px 0px 0px;
	color:#34495e;
}

.header-notification-indicator {
	position: absolute;
    top: 0px;
    right: -8px;
    color: #f783ac;
    font-size: 8px;
}

.header-notification-empty-container {
	padding:20px;
	text-align: center;
}

#notification-container-header-container {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 30px;
	align-items: center;
	padding:15px 20px 0px 20px;
}

.notification-container-header {
	margin: 0px;
}

.notification-container {
	display: grid;
	grid-template-columns: auto 1fr 30px;
	padding:5px 0px 5px 0px;
	align-items: center;
	color:#2c3e50;
}

.notification-container:hover {
	background-color:#fafafa;
}

.notification-icon {
	display: flex;
	padding:20px;
	border-right: 2px solid #efefef;
	color:#2c3e50;
	font-size: 18px;
}

.notification-content {
	padding:10px 10px 10px 20px;
}

.notification-indicator {
	display: flex;
    color: #f783ac;
    font-size: 8px;
    justify-self: center;
}

.header-current-year-container {
  position: relative;
  border:1px solid #efefef;
  border-radius: 6px;
  padding: 3px 12px;
  cursor: pointer;
}

.header-current-year-arrow {
  position: absolute;
  top: 9px;
  right: 10px;
  font-size: 12px;
}

.header-dropdown-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 15px;
  align-items: center;
  cursor: pointer;
  color: #2c3e50;
}

.header-dropdown-link {
	color:#a9a9a9;
	margin:2px 0px 0px 0px;
}

.header-dropdown-content {
  position: absolute;
  text-align: left;
  top: 41px;
  right: 0px;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background-color: #fff;
  font-size: 0.9em;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
  z-index: 999;
}

.header-dropdown-content-option {
  position: relative;
	display: block;
	/* width: 100%; */
	padding: 10px 15px;
	color: #2c3e50;
}

.header-dropdown-content-option:not(.header-dropdown-content-option-selected):hover {
  background-color: #fafafa;
  color: #000;
}

.header-dropdown-content-option-selected {
  background-color:#2c3e50;
  color:#fff;
}

.header-user-info {
	font-size: 10px;
}

.header-school-info {
	font-size: 12px;
}

.school-setup-main-container {
	width:100%;
	padding:40px 80px 200px 50px;
	text-align: left;
}

#main-content {
	height: 100%;
  z-index: 1;
  /*padding: 0px 0px 100px 0px;*/
}

.main-header {
	text-align:center;
	font-size:65px;
	opacity: 0.0;
	animation: fadeIn ease 3s forwards;
	-webkit-animation: fadeIn ease 3s forwards;
	color:#2c3e50;
}

.subheader {
	width:80%;
	text-align:center;
	margin:0px auto 15px auto;
	opacity: 0.0;
	animation: fadeIn ease 3s forwards;
	-webkit-animation: fadeIn ease 3s forwards;
	color:#34495e;
}

.subscreen-main-header {
	font-size:45px;
}

.subscreen-subheader {
	font-size:20px;
	margin:0px 0px 40px 0px;
}

.btn {
  display: block;
  margin: 0px auto 0px auto;
  width: 100%;
  padding:8px 20px 8px 20px;
  font-size: 16px;
  background-color: #516374;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.btn-round {
  border-radius: 20px;
}

.btn-small.btn-round {
  border-radius: 5px;
}

.btn-large.btn-round {
  border-radius: 30px;
}

.btn-small {
  padding:5px 10px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.btn-large {
  padding:15px 20px;
  font-size: 16px;
}

.btn-extra-large {
  padding:20px 30px;
}

.btn:hover {
  background-color: #617485;
}

.btn-icon-left {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 15px;
	align-items: center;
}

.btn-icon-right {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 15px;
	align-items: center;
}

.red-btn {
  background-color: #c0392b !important;
  color: #fff !important;
  cursor: pointer;
}

.red-btn:hover {
  background-color: #e74c3c !important;
}

.dark-orange-btn {
  background-color: #d35400 !important;
  color: #fff !important;
  cursor: pointer;
}

.dark-orange-btn:hover {
  background-color: #e67e22 !important;
}

.pastel-yellow-btn {
	background-color: #fdcb6e !important;
	color:#fff !important;
  cursor: pointer;
}

.pastel-yellow-btn:hover {
  background-color: #f9ce7d !important;
}

.purple-btn {
  background-color: #8e44ad !important;
  color: #fff !important;
  cursor: pointer;
}

.purple-btn:hover {
  background-color: #9b59b6 !important;
}

.dark-purple-btn {
  background-color: #474CCB !important;
  color: #fff !important;
  cursor: pointer;
}

.dark-purple-btn:hover {
  background-color: #4f54d4 !important;
}

.orange-btn {
  background-color: #e67e22 !important;
  color: #fff !important;
  cursor: pointer;
}

.orange-btn:hover {
  background-color: #d67622 !important;
}

.dark-blue-btn {
  background-color: #34495e !important;
  color: #fff !important;
  cursor: pointer;
}

.dark-blue-btn:hover {
  background-color: #2c3e50 !important;
}

.blue-btn {
  background-color: #2980b9 !important;
  color: #fff !important;
  cursor: pointer;
}

.blue-btn:hover {
  background-color: #3498db !important;
}

.green-btn {
  background-color: #27ae60 !important;
  color: #fff !important;
  cursor: pointer;
}

.green-btn:hover {
  background-color: #2ecc71 !important;
}

.turquoise-btn {
  background-color: #16a085 !important;
  color: #fff !important;
  cursor: pointer;
}

.turquoise-btn:hover {
  background-color: #1abc9c !important;
}

.gray-btn {
  background-color: #ecf0f1 !important;
  color: #a9a9a9 !important;
  cursor: pointer;
}

.gray-btn:hover {
  background-color: #e3e3e3 !important;
  color: #9c9c9c !important;
}

.gray-to-blue-btn {
  background-color: #ecf0f1 !important;
  color: #a9a9a9 !important;
  cursor: pointer;
}

.gray-to-blue-btn:hover {
  background-color: #3498db !important;
  color: #fff !important;
}

.gray-to-green-btn {
  background-color: #ecf0f1 !important;
  color: #a9a9a9 !important;
  cursor: pointer;
}

.gray-to-green-btn:hover {
  background-color: #2ecc71 !important;
  color: #fff !important;
}

.gray-to-red-btn {
  background-color: #ecf0f1 !important;
  color: #a9a9a9 !important;
  cursor: pointer;
}

.gray-to-red-btn:hover {
  background-color: #e74c3c !important;
  color: #fff !important;
}

.logout-btn {
  float: right;
  padding: 5px 10px 5px 10px;
  background-color: #efefef;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #a9a9a9;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: rgba(240, 240, 240, 0.85);
  color: #999999;
}

.btn-selected-checkmark {
	position: absolute;
    height: 20px;
    right: 6px;
    top: 6px;
}

.redirect-btn {
	display: inline-block;
	position: absolute;
	top:25px;
	left:25px;
	color: #34495e;
	font-size: 14px;
	text-decoration: none;
	cursor: pointer;
}

.redirect-btn:hover {
	color: #000;
}

.redirect-btn-icon {
	font-size: 16px;
	margin:0px 15px 0px 0px;
}

.mark-as-complete-btn {
    display: grid;
    min-width: 190px;
    /*grid-template-columns: 20px 1fr;
    grid-column-gap: 5px;
    align-items: center;*/
    justify-items: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px 10px 6px 15px;
    margin: 0px 0px 0px 0px;
    text-align: center;
    border: 1px solid #d6d6d6;
    border-radius: 7px;
    background-color: #fff;
    color: #2c3e50;
    cursor: pointer;
}

.mark-as-complete-btn:hover {
	color:#27ae60;
	background-color:#f0fff6;
    border: 1px solid #27ae60;
}

.load-more-btn {
	margin: 40px auto 50px auto;
    width: 200px;
}

.form-warning {
	font-size:12px;
	margin:-10px 0px 10px 0px;
	color:red;
}

#screens-content {
	background-color:#F5F7FA; 
}

/* Start by setting display:none to make this hidden.
   Then we position it in relation to the viewport window
   with position:fixed. Width, height, top and left speak
   for themselves. Background we set to 80% white with
   our animation centered, and no-repeating */
.loading_gif {
  display: none;
  width: 100px;
  height: 100px;
  background-color: purple;
  margin: auto;
  z-index: 1000;
  background: rgba(255, 255, 255, 0) url("http://i.stack.imgur.com/FhHRx.gif")
    50% 50% no-repeat;
}

.loading-container {
	width: 100%;
	text-align: center;
}

#loading-container {
  margin-top: -60px;
  display: none;
}

#cube-loader-screen {
  height:800px;
}

#cube-loader-container {
  position: absolute;
  width: 180px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -75%);
  text-align: center;
}

#cube-loader-inner-container {
  position: relative;
  width: 100%;
}

#cube-loading-calendar {
  	position: absolute;
	width: 150px;
	height: 200px;
	top: -76px;
	left: 15px;
}

#calendar-inside {
	position: absolute;
    width: 86px;
    top: 9px;
    left: 48px;
}

#calendar-inside-inner {
	position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:10px;
}

.calendar-block-container {
	position: relative;
	width:100%;
	height:22px;
}

.calendar-block {
	position: absolute;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	border-radius: 3px;
}

.calendar-block-1 {
	
	animation: bounce-1 1s infinite;
}

.calendar-block-2 {
	animation: bounce-2 1s infinite;
}

.calendar-block-3 {
	animation: bounce-3 1s infinite;
}

@keyframes bounce-1 {
  0%       { top:0px; }
  25%, 75% { top:1px; }
  50%      { top:3px; }
  100%     { top:1px; }
}

@keyframes bounce-2 {
  0%       { top:1px; }
  25%, 75% { top:0px; }
  50%      { top:1px; }
  100%     { top:3px; }
}

@keyframes bounce-3 {
  0%       { top:1px; }
  25%, 75% { top:3px; }
  50%      { top:1px; }
  100%     { top:0px; }
}

.parallelogram {
	position:absolute;
	top:180px;
	left:20px;
	width: 140px;
	height: 30px;
	transform: skew(-40deg);
	background: #000;
}

.dropdown-container {
	position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  align-items: center;
  padding: 7px 5px 7px 10px;
  background-color: #f0f5fb;
  border: 1px solid #efefef;
	border-radius: 5px;
	cursor: pointer;
}

.dropdown-container.dropdown-disabled {
	background-color:#efefef;
	cursor:text;
}

.dropdown-display {
	justify-self:left;
	font-size: 14px;
	color:#2980b9;
}

.dropdown-container.dropdown-disabled > .dropdown-display {
	color:#a9a9a9;
}

.dropdown-display.dropdown-display-default {
	color:#2c3e50;
}

.dropdown-arrow {
	justify-self: right;
	color:#2980b9;
}

.dropdown-options-container {
	position: absolute;
	width: 100%;
	min-width: 150px;
	max-height: 250px;
	top: 42px;
	right: 0px;
	background-color: #fff;
	border: 1px solid #efefef;
	border-top: none;
	margin-top: -8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	overflow-y: auto;
	z-index: 99999;
}

.dropdown-option {
  width: 100%;
  text-align: left;
  padding: 10px;
  color: #a9a9a9;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-option-selected {
  background-color: #3498db;
  color: #fff !important;
}

.dropdown-option:not(.dropdown-option-selected):hover {
  background-color: #f9f9f9;
  color: #000;
}

.dropdown_container {
  position: relative;
  width: 100%;
}

.dropdown_display_container {
  display: table;
  width: 100%;
  height: 32px;
  line-height: 32px;
  background-color: #16a085;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  z-index: 800;
}

.dropdown_disabled {
  cursor: not-allowed !important;
}

.dropdown_display_text {
  float: left;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  margin-left: 10px;
  font-size: 14px;
  max-width: 76%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown_display_arrow {
  float: right;
  display: table-cell;
  vertical-align: middle;
  padding: 3px 5px 0px 0px;
  margin: 0px 1px 0px 0px;
}

.dropdown_display_arrow.small-text {
  padding: 1px 10px 0px 0px;
}

.dropdown_option_container {
  position: absolute;
  width: 100%;
  top: 42px;
  right: 0px;
  min-width: 200px;
  max-height: 300px;
  background-color: #fff;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  margin-top: -8px;
  display: none;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
  overflow-y: auto;
  z-index: 9999;
}

.dropdown_option {
  width: 100%;
  padding: 5px 5px 5px 10px;
  cursor: pointer;
  color: #a9a9a9;
  font-size: 14px;
}

.dropdown_option_selected {
  background-color: #3498db;
  color: #fff;
}

.dropdown_option:not(.dropdown_option_selected):hover {
  background-color: #f9f9f9;
  color: #000;
}

.list-filter-container {
	position: relative;
}

.list-filter-icon {
	justify-self: right;
    font-size: 10px;
    cursor: pointer;
}

.list-filter-options-container {
	position: absolute;
	width: 100%;
	min-width: 250px;
  max-width: 400px;
	max-height: 500px;
	top: 42px;
	right: 0px;
	background-color: #fff;
	border: 1px solid #efefef;
	border-top: none;
	padding: 10px 20px 20px 20px;
	margin-top: -8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	overflow-y: auto;
  font-size: 14px;
	z-index: 99999;
}

.list-filter-option {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 5px 20px 5px 15px;
  color: #2c3e50;
  font-size: 14px;
}

.list-filter-title-container {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
  padding: 15px 0px 5px 0px;
  font-weight: bold;
  color: #2c3e50;
  font-size: 14px;
}

.page-with-sidebar-container {
	display: flex;
	flex-flow: column;
	height:100%;
}

.page-with-sidebar {
	display: grid;
	width:100%;
	height:100%;
	grid-template-columns: 300px 1fr;
	grid-template-rows: 1fr;
}

.page-with-sidebar-minimized {
	grid-template-columns: 40px 1fr;
}

.page-with-sidebar-minimized-console-expanded {
	grid-template-columns: 40px 1fr 350px;
}

.page-with-sidebar-minimized-console-minimized {
	grid-template-columns: 40px 1fr 40px;
}

.page-with-sidebar-sidebar {
	position: relative;
	background-color:#1a2836;
	color:#fff;
	padding: 20px 0px 0px 0px;
}

.page-with-sidebar-hide-icon {
	position: absolute;
	top:3px;
	right:3px;
	cursor: pointer;
}

.page-with-sidebar-content {
	position: relative;
  height: 100%;
	padding:0px 0px 100px 0px;
  overflow-y: auto;
	scroll-behavior: smooth;
}

.page-with-sidebar-content-inner {
	position: relative;
  height: fit-content;
}

.main-page-with-sidebar-container {
	display: flex;
	flex-flow: column;
	height:100%;
}

.main-page-with-sidebar {
	display: grid;
	width:100%;
	height:100%;
	grid-template-columns: 100px 1fr;
	grid-template-rows: 1fr;
}

.main-page-with-sidebar-minimized {
	grid-template-columns: 40px 1fr;
}

.main-page-with-sidebar-minimized-console-expanded {
	grid-template-columns: 40px 1fr 350px;
}

.main-page-with-sidebar-minimized-console-minimized {
	grid-template-columns: 40px 1fr 40px;
}

.main-page-with-sidebar-sidebar {
	position: relative;
	background-color:#1a2836;
	color:#fff;
	padding: 20px 0px 0px 0px;
  z-index: 1000;
}

.main-page-with-sidebar-hide-icon {
	position: absolute;
	top:3px;
	right:3px;
	cursor: pointer;
}

.main-page-with-sidebar-content {
	position: relative;
  height: 100%;
	/*padding:0px 0px 100px 0px;*/
  overflow-y: auto;
	scroll-behavior: smooth;
}

.main-page-with-sidebar-content-inner {
	position: relative;
  height: 100%;
  /*z-index: 1;*/
}

.main-sidebar-logo-outer-container {
	display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0px 0px 20px 0px;
}

.main-sidebar-logo-container {
	color:#fff;
}

.main-sidebar-logo-img {
	height: 30px;
    margin: -2px 0px 0px 0px;
}

.main-sidebar-logo-text {
	color:#465b70;
	font-size: 20px;
}

.main-sidebar-header {
	padding:10px 20px 10px 30px;
	color:#f39c12;
}

.main-sidebar-btn {
  display: block;
  padding: 30px 5px 30px 5px;
  text-align: center;
  font-size: 12px;
  color: #efefef;
  cursor: pointer;
}

.main-sidebar-btn:not(.main-sidebar-btn-selected):hover {
  color:#fff;
	background-color: #34495e;
}

.main-sidebar-btn-icon {
	font-size: 20px;
}

.main-sidebar-btn-selected {
  background-color: #465b70;
	/*background-color: #34495e;
  color:#fff !important;*/
}

.main-sidebar-btn-icon {
	font-size: 18px;
	margin:0px 0px 7px 0px;
}

#main-sidebar-settings-btn {
  position: absolute;
  width: 90px;
  bottom: 0px;
  left: 0px;
  padding: 20px 5px;
}

#main-sidebar-documentation-btn {
  position: absolute;
  width: 90px;
  bottom: 83px;
  left: 0px;
  padding: 20px 5px;
}

.sidebar-header {
	padding:10px 20px 10px 30px;
	color:#f39c12;
}

.sidebar-btn {
    display: block;
	padding: 20px 20px 20px 40px;
    color: #efefef;
    cursor: pointer;
}

.sidebar-btn:hover {
	background-color: #34495e;
}

.sidebar-btn-icon {
	font-size: 20px;
}

.sidebar-btn-minimized {
	padding:20px 10px 20px 10px !important;
}

.sidebar-btn-selected {
	background-color: #34495e;
}

.sidebar-btn-icon {
	font-size: 18px;
	margin:0px 20px 0px 0px;
}

.screen-sidebar {
	background-color: #2c3e50;
	text-align: left;
	padding: 0px 0px 20px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	height: fit-content;
}

.screen-sidebar-btn {
	position: relative;
	width:100%;
	padding:20px 20px 20px 40px;
	font-size: 14px;
	color:#8ba7c4;
	cursor: pointer;
}

.screen-sidebar-btn:not(.screen-sidebar-btn-selected):hover {
	background-color:#34495e;
	color:#efefef;
}

.screen-sidebar-icon {
	margin:0px 14px 0px 0px;
}

.screen-sidebar-btn-selected {
	background-color:#2980b9;
	color:#fff;
}

.screen-sidebar-btn-selected > .green-text {
	color:#97d7b2 !important;
}

.screen-sidebar-btn-selected > .yellow-text {
	color:#d7d197 !important;
}

.screen-sidebar-btn-selected > .dark-blue-gray-text {
	color:#3498db !important;
}

.screen-sidebar-btn:not(.screen-sidebar-btn-selected):hover > .dark-blue-gray-text {
	color:#2c3e50 !important;
}

.screen-sidebar-header {
	width:100%;
	padding: 25px 0px 20px 25px;
	font-size: 14px;
	font-weight: bold;
	color:#fff;
}

.screen-sidebar-separator {
	height:1px;
	width: 80%;
	/*margin:5px auto 5px auto;
	background-color:#efefef;*/
}

.screen-sidebar-department-complete-check {
	position: absolute;
	display: flex;
	top:22px;
	right:15px;
	font-size:20px;
}

.secondary-sidebar-header {
  padding:20px 20px 10px 20px;
  font-size: 16px;
  font-weight: bold;
}

.table_container {
  position: relative;
  width: 100%;
  min-width: 800px;
  height: 100%;
  margin: 0px auto 0px auto;
}

.table_options_container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20%;
  min-width: 280px;
  height: 100%;
  padding: 0px 0px 0px 0px;
  background-color: #1a2836;
  color: #cad1d2;
  z-index: 100;
}

#table-options {
  position: relative;
  height: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  overflow: hidden;
  min-height: 534px;
}

.table_display_container {
  position: absolute;
  top: 0px;
  left: 20%;
  width: 80%;
  min-width: 880px;
  height: 100%;
  padding: 35px 0px 100px 20px;
  background-color: #f5f7fa;
  /*background-color: #ecf0f1;*/
  overflow-y: auto;
  z-index: 15;
}

.table_console_container {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 18%;
  min-width: 195px;
  height: 100%;
  background-color: #fff;
  /*border-left:1px solid #000;*/
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: none;
}

#table_console_upper_container {
  width: 100%;
  height: 70%;
  position: relative;
  overflow-y: auto;
}

#table_console_lower_container {
  width: 100%;
  height: 30%;
  position: relative;
  overflow-y: auto;
}

#table_console_header {
  position: fixed;
  margin-left: 0px;
  margin-top: 0px;
  width: 18%;
  min-width: 195px;
  text-align: center;
  height: 40px;
  border-bottom: 1px solid #aaaaaa;
}

.table_console_header_option {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  padding: 0px 10px 0px 10px;
  background-color: #efefef;
  border-bottom: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  color: #34495e;
  cursor: pointer;
  z-index: 5;
}

.table_console_header_option:not(.table_console_option_selected):hover {
  background-color: #e5e5e5;
}

.table_console_option_selected {
  border-bottom: 3px solid #3498db;
}

.table_console_option_text {
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -30px;
  width: 60px !important;
  padding: 0px;
}

.table_console_badge {
  position: absolute;
  top: 12px;
  right: 5px;
  padding: 2px 5px 2px 5px;
  background-color: #fff;
  color: #2980b9;
  font-size: 9px;
  border-radius: 5px;
}

.console_content {
  width: 100%;
  height: 100%;
  padding: 100px 15px 5px 15px;
  overflow-y: auto;
}

#saved_schedules_header {
  width: 100%;
  padding: 10px;
  background-color: #efefef;
  color: #34495e;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
}

#hide-sidebar,
#show-sidebar {
  position: absolute;
  top: 3px;
  right: 3px;
  height: 15px;
  cursor: pointer;
}

#show-hide-console {
  position: absolute;
  height: 15px;
  top: 1px;
  left: -18px;
  z-index: 16;
}

#hide-console,
#show-console {
  height: 15px;
  cursor: pointer;
}

.table_options_logo {
  position: relative;
  width: 150px;
  padding: 0px 0px 0px 30px;
  margin: -15px 0px 0px 0px;
  text-decoration: none;
}

.table_options_logo_img {
  display: inline-block;
  margin: 0px 0px -2px 0px;
  height: 24px;
}

.table_options_logo_text {
  display: inline-block;
  margin: 30px 0px 20px 10px;
  color: #f5f7fa;
  font-size: 1.5em;
}

.table_option_heading {
  font-size: 12px;
  color: #537291;
  padding: 8px 10px 6px 15px;
  border-top: 1px solid #1d2f43;
  margin-top: 15px;
}

.table_option {
  width: 100%;
  padding: 10px 20px 10px 30px;
  cursor: pointer;
}

.table_option:hover {
  background-color: #3f5266;
  color: #fff;
}

.table_option_button {
  width: 100%;
  min-width: 176px;
  color: #fff;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  cursor: pointer;
  border-top: 1px solid #34495e;
}

.table_option_button:hover {
  background-color: #34495e;
}

.table_option_selected {
  background-color: rgba(255, 255, 255, 0.24);
  color: #fff;
}

.table_option_selected:hover {
  background-color: rgba(255, 255, 255, 0.24) !important;
  color: #fff !important;
}

.table_option_img {
  display: inline-block;
  position: relative;
  height: 15px;
  margin-top: 2px;
}

.table_option_text {
  display: inline-block;
  margin-left: 12px;
  cursor: pointer;
}

.table_option_text:hover {
  color: #efefef;
}

.table_option_hidden {
  display: none;
}

.content_container {
  display: none;
  width: 100%;
  padding: 50px 30px 30px 20px !important;
  z-index: 15;
}

.current_content {
  display: block !important;
}

.table-header-row {
  position: relative;
  width: 100%;
  background-color: #cad1d2;
  color: #000;
  border-bottom: 1px solid #efefef;
  padding: 5px 10px 5px 10px;
}

.table-col {
  float: left;
  border: 1px solid #efefef;
  min-width: 80px;
}

.table-col:not(:first-child) {
  border-left: none;
}

.table-row {
  width: 100%;
  display: block;
  padding: 5px 10px 5px 10px;
  border-bottom: 1px solid #efefef;
  background-color: #fff;
  color: #000;
}

/*.table-row:last-child {
	border-bottom: none;
}*/

.schedule-table-header-row-inv {
  width: 100%;
  padding: 5px 10px 5px 10px;
}

.schedule-table-header-col-inv {
  float: left;
  position: relative;
  width: 80px;
  color: #000;
  padding: 5px 10px 5px 10px;
  margin: 0px 0px 0px 10px;
  text-align: center;
}

.schedule-table-row-inv {
  position: relative;
  width: 100%;
  padding: 10px;
  margin: 10px 0px 0px 0px;
}

.schedule-table-col-inv {
  position: relative;
  float: left;
  width: 120px;
  text-align: center;
  margin: 0px 0px 0px 10px;
}

.table-header-row-inv {
  width: 100%;
  /*border-bottom: 1px solid #a9a9a9;*/
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
}

.table-row-inv {
  position: relative;
  width: 100%;
  background-color: #fff;
  /*border-bottom: 1px solid #a9a9a9;*/
  padding: 10px;
}

.table-row-inv:nth-child(odd) {
  background-color: #f9f9f9;
}

.table-col-inv {
  position: relative;
  float: left;
}

.table-col-inv:not(:first-child) {
  border-left: none;
}

.search_bar {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border: 2px solid #ddd;
  border-radius: 20px;
  padding-left: 15px;
  outline: none;
}

.added-template {
	display: none;
}

.remove-data {
  position: relative;
  height: 25px;
  width: 25px;
  font-size: 16px;
  text-align: center;
  border-radius: 15px;
  background-color: rgb(231, 76, 60);
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-content: center;
}

.remove-data:hover {
  background-color: rgba(232, 77, 60, 0.85);
}

.remove-data-inverse {
  position: relative;
  font-size: 16px;
  text-align: center;
  border-radius: 15px;
  padding: 3px 8px 3px 8px;
  color: #515151;
  cursor: pointer;
}

.remove-data-inverse:hover {
  color: #000;
  background-color: #fafafa;
}

.radio-btn {
  width: 20px;
  height:20px;
  border-radius: 50px;
  border:2px solid #ddd;
  padding:2px;
  background-clip: content-box;
  cursor: pointer;
}

.radio-btn-selected {
  background-color: #3498db;
}

.radio-btn:not(.radio-btn-selected):hover {
  background-color: #3498db;
}

.radio_button_container {
  width: 50px;
  height: 27px;
  line-height: 27px;
  border: 1px solid #bcbcbc;
  border-radius: 20px;
  background-color: #efefef;
  padding: 2px 1px 1px 1px;
}

.radio_button_selected {
  background-color: #3498db;
  /*background-color: #667e94;*/
}

.radio_button_left {
  float: left;
  width: 21px;
  height: 21px;
  border: 1px solid #bcbcbc;
  border-radius: 13px;
  background-color: #fff;
  cursor: pointer;
}

.radio_button_right {
  float: right;
  width: 21px;
  height: 21px;
  border-radius: 13px;
  background-color: #fff;
  cursor: pointer;
  display: none;
}

.optn-selected-container {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background-color: #2980b9;
  cursor: pointer;
}

.optn-selected-container:hover {
  background-color: #3498db;
}

.optn-selected {
  float: left;
  padding: 0px 0px 0px 10px;
}

.select-arrow {
  float: right;
  font-size: 30px;
  padding: 0px 10px 0px 0px;
}

.select-optn-container {
  width: 100%;
  max-height: 100px;
  position: absolute;
  top: 30px;
  cursor: pointer;
  z-index: 999;
  overflow-y: scroll;
  border: 1px solid #efefef;
}

.optn-selected-container-small {
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  background-color: #bdc3c7;
  color: #000;
}

.optn-selected-container-small:hover {
  background-color: #bdc3c5;
}

.select-arrow-small {
  float: right;
  font-size: 12px;
  padding: 0px 2px 0px 0px;
}

.optn-selected-small {
  float: left;
  padding: 0px 0px 0px 4px;
}

.select-optn-container-small {
  width: 100%;
  max-height: 100px;
  position: absolute;
  top: 16px;
  cursor: pointer;
  z-index: 999;
  overflow-y: scroll;
  border: 1px solid #efefef;
}

.select-optn {
  width: 100%;
  font-size: 12px;
  background-color: #fff;
  padding: 3px 5px 3px 5px;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}

.select-optn:hover {
  background-color: #efefef;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input[type="text"] {
  width: 100%;
  border: 2px solid #dfdfdf;
  border-radius: 20px;
  margin: 8px 0;
  outline: none;
  padding: 8px 8px 8px 40px;
  box-sizing: border-box;
  transition: 0.3s;
}

.input-with-icon input[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 #a9a9a9;
}

.input-with-icon i {
  position: absolute;
  font-size: 16px;
  left: 5px;
  top: 10px;
  padding: 9px 8px;
  color: #c0c0c0;
  transition: 0.3s;
}

.input-with-icon input[type="text"]:focus + i {
  color: dodgerBlue;
}

.input-with-icon.inputIconBg i {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.input-with-icon.inputIconBg input[type="text"]:focus + i {
  color: #fff;
  background-color: dodgerBlue;
}

.search-result-container {
  display: none;
  position: absolute;
  top: 44px;
  left: 0px;
  width: 100%;
  max-height: 300px;
  background-color: #fff;
  z-index: 9999;
  border: 1px solid #a9a9a9;
  overflow-y: auto;
}

.search-result-row {
  padding: 10px;
  cursor: pointer;
}

.search-result-row:hover {
  background-color: #a3bae3;
  color: #000;
}

.add-btn {
  float: right;
  font-size: 20px;
  color: #27ae60;
  cursor: pointer;
}

.add-btn:hover {
  color: #2ecc71;
}

.error-message {
  width: 100%;
  padding: 5px;
  background-color: #e74c3c;
  color: #fff;
  border: 2px solid #c0392b;
  border-radius: 4px;
}

.error-message-no-box {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #e84d3c;
}

.success-message-no-box {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #27ae60;
}

.error {
  border-color: #c0392b !important;
}

.get-more-info {
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.more-info {
  display: none;
  width: 300px;
  padding: 0px 20px 10px 20px;
  position: absolute;
  text-align: left;
  top: 20px;
  right: 0px;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 999;
}

.more-info p {
  font-size: 12px;
}

.info-icon {
  position: relative;
  cursor: pointer;
}

.explanation-popup {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 420px;
	margin: 0px auto 15px auto;
	font-size: 14px;
	background-color: #fff;
	padding: 10px 20px 20px 20px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	z-index: 9999;
}

.close-explanation {
	position: absolute;
	top: 0px;
	right: 0px;
	color: #aaa;
	padding: 10px;
	border-radius: 15px;
	cursor: pointer;
}

.close-explanation:hover {
  color: #000;
  background-color: #efefef;
  opacity: 0.2;
}

.navigation_screen {
  position: relative;
  display: none;
  padding: 10px 20px 35px 20px;
  background-color: #fff;
  margin: 40px 0px 0px 0px;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.navigation_back {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #efefef;
  font-size: 30px;
  cursor: pointer;
}

.navigation_back:hover {
  color: #2c3e50;
}

.checkmark_container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  color:#868686;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 50px;
  height: 16px;
  width: 16px;
  margin-left: -8px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkmark_container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkmark_container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark_container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fas-checkbox-checked {
  color: #2196f3;
  cursor: pointer;
}

.fas-checkbox-unchecked {
  color: #eee;
  cursor: pointer;
}

.fas-checkbox-unchecked:hover {
  color: #ccc;
}

.checkmark-disabled {
  color: #34495e !important;
  cursor: not-allowed !important;
}

.uncheckmark-disabled {
  color: #ccc !important;
  cursor: not-allowed !important;
}

.select-all {
  color: #2980b9;
  cursor: pointer;
}

.select-all:hover {
  color: #3498db;
}

.select-all-row {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 15px;
  column-gap: 15px;
  align-items: center;
  font-size: 16px;
  margin:0px 6px;
}

.select-all-container {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 7px;
  column-gap: 7px;
  align-items: center;
  font-size: 16px;
  padding: 10px 6px;
  margin: 3px 0px;
  border-radius: 5px;
  color: #a9a9a9;
  cursor: pointer;
}

.select-all-container:hover, .select-all-action-btn:hover {
  background-color: #f5f5f5;
}

.select-all-arrow {
  margin-top:-6px;
}

.select-all-actions-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 40px));
  text-align: center;
}

.select-all-action-btn {
  font-size: 16px;
  padding:10px;
  border-radius: 5px;
  color:#a9a9a9;
  cursor: pointer;
  margin:3px 0px;
}

.fixed-heading-on-scroll {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 9999;
}

.toggle-btn-container {
  position: relative;
  height: 30px;
  width: 56px;
  border: 2px solid #ddd;
  border-radius: 30px;
  background-color: #efefef;
}

.toggle-btn-container-small {
  position: relative;
  height: 20px;
  width: 34px;
  border: 2px solid #ddd;
  border-radius: 30px;
  background-color: #efefef;
}

.toggle-btn-container-selected {
	background-color: #3498db;
	border:2px solid #3498db;
}

.toggle-btn-container-selected-yellow {
	background-color: #f39c12;
	border:2px solid #f39c12;
}

.toggle-btn-unselected {
	position: absolute;
	left:-2px;
	top:-2px;
	height:30px;
	width:30px;
	background-color: #fff;
	border:2px solid #ddd;
	border-radius: 30px;
	cursor: pointer;
}

.toggle-btn-unselected-small {
  position: absolute;
	left:-2px;
	top:-2px;
	height:20px;
	width:20px;
	background-color: #fff;
	border:2px solid #ddd;
	border-radius: 30px;
	cursor: pointer;
}

.toggle-btn-selected {
	position: absolute;
	right:-2px;
	top:-2px;
	height:30px;
	width:30px;
	background-color: #fff;
	border:2px solid #3498db;
	border-radius: 30px;
	cursor: pointer;
}

.toggle-btn-selected-small {
	position: absolute;
	right:-2px;
	top:-2px;
	height:20px;
	width:20px;
	background-color: #fff;
	border:2px solid #3498db;
	border-radius: 30px;
	cursor: pointer;
}

.toggle-btn-border-blue {
	border:2px solid #3498db !important;
}

.toggle-btn-border-yellow {
	border:2px solid #f39c12 !important;
}

.toggle-with-text-btn-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	max-width: 300px;
	min-width: 150px;
}

.toggle-with-text-btn-optn {
	align-self: center;
	text-align: center;
	padding: 6px;
	/* border: 1px solid #dfdfdf; */
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	color: #a9a9a9;
	background-color: #fff;
	cursor: pointer;
}

.toggle-with-text-btn-optn-selected {
	background-color: #2980b9;
	color: #fff;
}

.toggle-with-text-btn-optn ~ .toggle-with-text-btn-optn {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.toggle-with-text-btn-optn:not(.toggle-with-text-btn-optn-selected):hover {
  background-color: #fafafa;
  color:#5c5c5c;
}

.sort-data {
  font-size: 10px;
  cursor: pointer;
}

.checkmark-submit, .x-cancel {
  color: #ccc;
  cursor: pointer;
}

.x-remove {
  color: #ccc;
  cursor: pointer;
}

.checkmark-submit:hover {
  color: #27ae60;
}

.x-cancel:hover {
  color: #c0392b;
}

.x-remove:hover {
	color:#a9a9a9;
}

.option-btn {
  display: inline-block;
  padding: 10px 20px 10px 20px;
  margin: 10px 10px 0px 0px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
  font-size: 20px;
  cursor: pointer;
}

.option-btn-small {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  margin: 5px 5px 0px 0px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
  font-size: 12px;
  cursor: pointer;
}

.option-btn:not(.block-editing-content):not(.option-btn-no-hover):hover,
.option-btn-selected {
  background-color: #3498db;
  color: #fff;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.55);
}

.option-btn-small:not(.block-editing-content):not(.option-btn-no-hover):hover,
.option-btn-small-selected {
  background-color: #3498db;
  color: #fff;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.55);
}

.filtered {
  display: none !important;
}

.filter-parent-container {
  position: relative;
  text-align: left;
}

.filter-btn {
  display: table;
  cursor: pointer;
}

.filter-btn i {
  display: table-cell;
  vertical-align: middle;
}

.filter-btn-inverse {
  padding: 5px 6px 4px 6px;
  background-color: #fff;
  color: #2c3e50;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.filter-btn:hover {
  background-color: #e7e7e7;
}

.filter-btn-inverse:hover {
  color: #34495e;
}

.filter-container {
  display: none;
  position: absolute;
  top: 26px;
  right: 0px;
  width: 250px;
  max-height: 400px;
  padding: 10px 10px 20px 10px;
  color: #2c3e50;
  background-color: #fff;
  overflow-y: auto;
  z-index: 9999;
}

.filter-inner-container {
  width: 100%;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #efefef;
}

.filter-inner-container-heading {
  margin: 0px;
  padding: 5px 10px 5px 10px;
}

.filter-option-container {
  padding: 5px 10px 5px 15px;
  font-size: 12px;
}

.filter-option-title {
  display: inline-block;
  width: 90%;
}

.filter-option-select {
  display: inline-block;
  width: 10%;
  text-align: right;
}

.fa-checkbox {
  cursor: pointer;
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
.shadow-none {
  box-shadow: none;
}

.cancel-other-container,
.submit-other-container {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 0px 0px 5px;
  color: #cdcdcd;
  cursor: pointer;
}

.cancel-other-container:hover {
  color: #c0392b;
}

.submit-other-container:hover {
  color: #2ecc71;
}

.preloader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  display: inline-block;
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: loaderspin 2s linear infinite;
}

@keyframes loaderspin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.toggle-screens-btn {
  display: inline-block;
  padding: 3px 15px 3px 15px;
  margin: 0px 0px 0px 0px;
  color: #a9a9a9;
  cursor: pointer;
}

.toggle-screens-btn-selected {
  color: #2980b9;
  border-bottom: 2px solid #2980b9;
}

.toggle-screens-btn:not(.toggle-screens-btn-selected):hover {
  color: #999999;
}

.toggle-container {
  display: none;
  text-align: left;
}

.toggle-container-selected {
  display: block;
}

/* MODAL CSS */
.sweet-alert { 
  margin: auto; 
  transform: translateX(-50%); 
}

.swal-overlay {
  z-index: 100000;
}

.swal-modal {
  z-index: 100001;
}

.swal-modal .swal-text {
    text-align: center !important;
}

.swal-wide {
  width:660px !important;
}

.swal-extra-wide {
  width:760px !important;
}

.sweet-alert-dont-show-message {
	width:100%;
	padding:20px 10px;
	border-top: 1px solid #efefef;
	border-bottom: 1px solid #efefef;
	margin:30px 0px 20px 0px;
}

.modal {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
	position: relative;
	margin: 15% auto;
	width:440px;
	height: auto !important;
	background-color:#fff;
	border-radius:10px;
	padding: 25px;
	/*overflow-y: hidden;*/
	z-index:99999 !important;
}

.modal-screen {
	position: fixed; /* Stay in place */
	z-index: 9999; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	background-color:#fff;
	overflow-y: auto;
  z-index: 99999 !important;
}

/* Modal Content/Box */
.modal-screen-content {
  position: relative;
  background-color: #fff;
  margin: 0px;
  border: none;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 50px 20px 100px 20px;
  text-align: center;
  z-index: 99999 !important;
}

.modal-label {
  font-size: 14px;
  margin-bottom: 3px;
}

.data-modal-heading {
	font-size:18px;
	width:100%;
	background-color:#2c3e50;
	color:#fff;
	padding:10px;
	margin:30px 0px 0px 0px;
}

.modal-close,
.data-modal-close {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  text-align: center;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;
  margin: 0px 0px 0px 0px;
}

.modal-close-right,
.data-modal-close-right {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  text-align: center;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;
  margin: 0px 0px 0px 0px;
}

.modal-close:hover,
.modal-close:focus,
.data-modal-close:hover,
.data-modal-close:focus,
.modal-close-right:hover,
.modal-close-right:focus,
.data-modal-close-right:hover,
.data-modal-close-right:focus  {
  color: black;
  text-decoration: none;
  cursor: pointer;
  background-color: #efefef;
}

.modal-save-btn {
  position: absolute;
  min-width: 160px;
  right: 25px;
  top: 0px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 15px;
  align-items: center;
  padding: 12px 20px 12px 20px;
  background-color: #34495e;
  color: #fff;
  margin: 20px 0px 0px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-save-btn:hover {
  background-color: #2c3e50;
}

.modal-delete-btn {
  position: absolute;
  right: 200px;
  top: 0px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 15px 20px 16px 20px;
  background-color: #c0392b;
  color: #fff;
  margin: 20px 0px 0px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-delete-btn:hover {
  background-color: #e74c3c;
}

.modal-top-icon {
  font-size:30px;
  padding:30px;
  border-radius:60px;
  background-color:#f0f5fb;
  margin:10px 0px 20px 0px;
}

.modal-btn-container {
  display:grid;
  grid-template-columns:1fr 1fr;
  column-gap:15px; 
  align-items:center;
  margin-top:30px;
}

.modal-example-list {
  max-height: 350px;
  overflow-y: auto;
  border:2px solid #ddd;
}

.modal-example-row {
  padding:5px 10px 5px 10px;
}

.left-btn, .right-btn {
	width: 100%;
	padding: 8px 0px 8px 0px;
	text-align: center;
	cursor: pointer;
}

.left-btn:hover, .right-btn:hover {
	opacity: .9;
}

#screen-loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.locked-screen-notification-container {
	width:600px;
	margin: 30px auto 30px auto;
	background-color:#fff;
	padding:30px 80px 30px 80px;
	border-radius:8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.supervisor-options-container {
	position: relative;
}

.data-options-container {
	position: absolute;
	width: 100%;
	min-width: 240px;
	max-width: 300px;
	max-height: 360px;
	top: 0px;
	left: 0px;
	background-color: #fff;
	border: 1px solid #efefef;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	overflow-y: auto;
	z-index: 99999;
}

.data-options-heading {
	display: block;
	margin:5px 0px 0px 0px;
	padding:3px 10px 3px 10px;
	color:#2c3e50;
	font-size: 12px;
}

.data-options-option {
	display: block;
	text-align: left;
	padding: 10px;
	color: #a9a9a9;
	font-size: 14px;
	cursor: pointer;
}

.data-options-option:not(.data-option-selected):hover {
	background-color: #f9f9f9;
	color: #000;
}

.data-option-selected {
	background-color: #3498db !important;
	color:#fff !important;
}

.fixed-bottom-save-container {
	position: fixed;
	width: 100%;
	bottom:0px;
	left:0px;
	padding:15px;
	text-align: right;
	background-color:#2c3e50;
	z-index: 999;
}

.search-add-row-container {
	position: relative;
	padding: 10px 15px 10px 15px;
	background-color: #fff;
	margin:10px 0px 15px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.search-add-search-container {
	width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-column-gap: 25px;
  padding: 0px 0px 0px 0px;
}

.search-add-adding-label {
	text-align: left;
	font-size: 12px;
	color:#a9a9a9;
}

.search-add-adding-input-container {
	position: relative;
}

.search-add-adding-input-icon {
	position: absolute;
    top: 12px;
    left: 14px;
    color: #ddd;
}

.search-add-adding-input {
	width: 100%;
	height:40px;
	line-height: 40px;
	border:2px solid #efefef;
	border-radius:20px;
	padding:0px 0px 0px 40px;
	font-size: 15px;
}

.search-add-adding-input:focus + .search-add-adding-input-icon {
	color: #2980b9 !important;
}

.search-add-cancel-new-btn {
	color:#ddd;
	justify-self:right;
	margin:0px 0px 0px 0px;
	cursor: pointer;
}

.search-add-cancel-new-btn:hover {
	color:#a9a9a9;
}

.search-add-search-results {
	margin:0px 0px 15px 0px;
	max-height: 300px;
	overflow-y: auto;
	font-size: 13px;
  padding: 0px 10px 0px 0px;
}

.search-add-did-you-mean {
  text-align: left;
	font-size: 14px;
  margin: 15px 0px 5px 0px;
  font-weight: bold;
  color: #e67e22;
}

.search-add-search-result {
	display: grid;
  max-width: 800px;
  grid-template-columns: 1fr auto;
  grid-column-gap: 20px;
  align-items: center;
  font-size: 14px;
  padding: 5px 7px 5px 7px;
}

.search-add-search-result:hover {
	background-color: #fbfbfb;
}

.search-add-add-btn {
	display: inline-block;
	text-align: center;
	padding:3px 10px 3px 10px;
	border-radius: 5px;
	background-color:#2980b9;
	color:#fff;
	cursor: pointer;
}

.search-add-add-btn:hover {
	background-color:#3498db;
}

.elem-suggestion-box-parent {
  position: relative;
}

.elem-suggestion-box {
  position: absolute;
  width: 100%;
  top: 0px;
  margin: 0px 0px 15px 0px;
  max-height: 300px;
  overflow-y: auto;
  font-size: 14px;
  padding: 10px;
  text-align: left;
  border: 1px solid #efefef;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
  z-index: 1;
}

.sort-toggle {
	font-size: 14px;
	cursor: pointer;
}

.breadcrumb-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	margin:auto;
}

.breadcrumb {
	position:relative;
	display: grid;
	grid-template-columns: 30px 1fr;
	align-items: center;
	min-width:210px;
	margin:0px 0px 30px 0px;
	padding:5px 10px 5px 40px;
	background-color: #fff;
	text-align:center;
	cursor: pointer;
	z-index: 1;
}

.breadcrumb-step-1 {
	text-align:left;
	padding-left:20px;
	border-radius: 5px 0px 0px 5px;
	z-index: 5;
}

.breadcrumb-step-2 {
	z-index: 4;
}

.breadcrumb-step-3 {
	z-index: 3;
}

.breadcrumb-step-4 {
	z-index: 2;
}

.breadcrumb-step-5 {
	z-index: 1;
}

.breadcrumb-number {
	font-size: 26px;
}

.breadcrumb:not(.breadcrumb-selected) > .breadcrumb-number, .breadcrumb:not(.breadcrumb-selected) > .breadcrumb-text {
	color:#cbcbcb;
}

.breadcrumb:not(.breadcrumb-selected):hover {
	background-color: #efefef;
}

.breadcrumb:not(.breadcrumb-selected):hover > .breadcrumb-number, .breadcrumb:not(.breadcrumb-selected):hover > .breadcrumb-text {
	color: #353535;
}

.breadcrumb:after {
    content:"";
    position:absolute;
    height:0;
    width:0;
    left:100%;
    top:-5px;
    border:29px solid transparent;
    border-left: 29px solid #F5F7FA;
    z-index:1000;
}

.breadcrumb:before {
    content:"";
    position:absolute;
    height:0;
    width:0;
    left:100%;
    top:1px;
    border:22px solid transparent;
    border-left: 22px solid #fff;
    z-index:1100;
}

.breadcrumb:hover:before {
	 border-left: 22px solid #efefef;
}

.breadcrumb-selected {
	background-color: #16a085;
	border:none;
	color:#fff;
	padding:5px 10px 5px 40px;
}

.breadcrumb-selected:hover {
	background-color: #16a085;
}

.breadcrumb-selected:after {
    content:"";
    position:absolute;
    height:0;
    width:0;
    left:100%;
    top:-5px;
    border:29px solid transparent;
    border-left: 29px solid #F5F7FA;
    z-index:1000;
}

.breadcrumb-selected:before {
    content:"";
    position:absolute;
    height:0;
    width:0;
    left:100%;
    top:1px;
    border:22px solid transparent;
    border-left: 22px solid #16a085;
}

.breadcrumb-selected:hover:before {
	 border-left: 22px solid #16a085;
}


.search-bar-container {
	position: relative;
	width:70%;
}

.search-bar {
	height:35px;
	line-height:35px;
	padding: 10px 20px 10px 35px;
	border-radius: 20px;
}

.search-bar-icon {
	position: absolute;
	top:10px;
	left:10px;
	color: #ddd;
}

.search-bar:focus + .search-bar-icon {
	color: #2980b9 !important;
}

.all-changes-saved-message {
	display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 15px;
    align-items: center;
    padding: 7px 10px 7px 10px;
    background-color: #fff;
    border: 3px solid #2ecc71;
    border-radius: 8px;
    z-index: 1;
}

.all-changes-saved-message > .fa-check-circle {
	font-size: 20px;
}

.all-changes-saved-message h5, .all-changes-saved-message h4 {
	margin:0px;
}

.department-supervisor-parent-container {
	position: relative;
	max-width: 70%;
  text-align: left;
}

.department-supervisor-outer-container {
	display: inline-block;
}

.department-supervisor-inner-container {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-column-gap: 15px;
	margin:0px 10px 5px 0px;
	align-items: center;
	border:1px solid #ddd;
	border-radius: 20px;
	padding:6px 10px 6px 10px;
	width: max-content;
  background-color: #fff;
}

.department-supervisor-user-icon {
	font-size: 25px;
}

.department-supervisor-name {
	font-size: 14px;
}

.department-supervisor-img {
	width:25px;
	height:25px;
	border-radius: 20px;
}

.percentage-container {
  position: relative;
}

.percentage-circle-default {
  fill: none;
  stroke: #f8f8f8;
  stroke-width: 3.8;
}

.percentage-circle-inner {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
}

.percentage-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-44%, -60%);
  text-align: center;
}

.percent-text {
  font-size:25px;
}

.percent-subtext {
  font-size: 12px;
  margin: -5px 0px 0px 0px;
}

.progress-bar-container {
  position: relative;
  height: 10px;
  width:100%;
  border-radius: 10px;
  background-color: #efefef;
}

.progress-bar-inner-container {
  height:10px;
  width: 0%;
  border-radius: 10px;
  background-color: #3498db;
}

.general-screen-message {
  width: 100%;
  display: grid;
  justify-items: center;
  text-align: center;
  padding: 50px 50px 50px 50px;
  margin: 30px auto 0px auto;
  border:2px dashed #efefef;
  border-radius: 10px;
  background-color:#fff;
  color: #2c3e50;
}

.general-screen-message-checkmark {
  margin:0px 0px 0px 0px;
  font-size: 60px;
}

.general-screen-message-btn-container {
  display: grid;
  /* grid-template-columns: 1fr auto; 
  grid-column-gap: 30px;
  column-gap: 30px;*/
  align-items: center;
  margin: 35px auto 0px auto;
  justify-items: right;
  width:280px;
  justify-items: center;
}

.general-screen-message-btn-container > .btn.turquoise-btn {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

#start-new-year-select-container {
  display: grid;
  row-gap: 10px;
  text-align: left;
  max-width: 240px;
  margin: 30px auto;
}

.start-new-year-select-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.start-new-year-select-subrow {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: 10px;
}

.disabled-row {
  opacity: .3;
}

.move-to-next-screen-btn {
  width: fit-content;
  min-width: 250px;
}

.underline-text {
  text-decoration: underline;
}

.underline-dashed {
  border-bottom: 1px dashed;
}

.under-maintenance-screen {
  width: fit-content;
  display: grid;
  grid-template-columns: 300px 500px;
  grid-column-gap: 100px;
  column-gap: 100px;
  margin: 100px auto;
  text-align: left;
}

.under-maintenance-logo-container {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  position: absolute;
  top:20px;
  left:20px;
  align-items: center;
}

.under-maintenance-logo-img {
  height: 30px;
}

.under-maintenance-logo-text {
  color:#465b70;
	font-size: 20px;
}

.under-maintenance-screen h1 {
  font-size: 60px;
  line-height: 65px;
}

.under-maintenance-screen p {
  font-size: 24px;
}

.under-maintenance-screen img {
  max-width: 500px;
}

.universal-toolbar {
  display: grid;
  row-gap: 10px;
  position: absolute;
  top: 250px;
  right: 20px;
  z-index: 9999 !important;
}

.universal-toolbar-btn {
  display: grid;
  background-color: #fff;
  padding: 8px 8px 8px 8px;
  border: 1px solid #efefef;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: #34495e !important;
  cursor: pointer;
}

.universal-toolbar-btn:hover {
  opacity: .7;
}