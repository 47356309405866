#schedule-structure-options-container {
	width:100%;
	margin:0px 0px 20px 0px;
}

.schedule-structure-header {
	margin:0px 0px 15px 0px;
	padding:0px;
}

#days-in-rotation-container {
	margin:0px 0px 30px 0px;
}

.ess-drag-dots {
	font-size:20px;
	color:#9b59b6;
}

#ess-main-header-container {
	background-color: #fff;
    padding: 20px 20px 28px 20px;
    border-radius: 70px 70px 10px 10px;
    margin: 70px 0px 0px 0px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	text-align: center;
}

#ess-parent-header {
	margin:0px 0px 0px 0px;
	padding:0px 0px 0px 0px;
	font-size: 30px;
	color:#2c3e50;
}

#edit-schedule-structure-container {
	position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: min-content 120px;
    grid-column-gap: 2rem;
    column-gap: 2rem;
    margin: 100px 0px 0px 0px;
}

#ess-header-container {
	width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
    align-items: center;
}

#ess-header-label {
	display: flex;
	height:100%;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.ess-header-day {
	position: relative;
	display: flex;
	height:100%;
	justify-content: center;
	align-items: center;
	gap:10px;
	padding:6px 15px;
    color: #2c3e50;
	background-color: #fafafa;
	border:2px solid #efefef;
	border-radius: 5px 5px 0px 0px;
	border-left: none;
	border-bottom: none;
	cursor: pointer;
}

.ess-header-day-selected {
	background-color: #fff;
	border-bottom: none;
    font-weight: bold;
}

.ess-header-day:not(.ess-header-day-selected):hover {
	background-color: #fff;
	border-bottom: none;
    font-weight: bold;
}

.ess-header-day-name {
	width: 100%;
	text-align: center;
	color:#2c3e50;
}

.ess-remove-header-day {
	position:absolute;
	top:3px;
	right:3px;
	font-size: 12px;
}

#ess-full-schedule-container {
	width: fit-content;
	display: grid;
    grid-template-columns: min-content 280px auto;
    grid-column-gap: 30px;
    column-gap: 30px;
	background-color: #fff;
	padding:20px 20px;
}

#ess-lunches-container {
	background-color: #f5f7fa;
	border-left: 1px dashed #000;
	border-right: 1px dashed #000;
	margin:10px 0px 0px 0px;
	padding:0px 20px;
}

.ess-drag-dots-container {
	display: flex;
	height:100%;
	justify-content: center;
	align-items: center;
}

#ess-lunch-block-parent-container {
	display: grid;
}

.ess-day-lunch-box {
	position: relative;
	padding:10px;
	border-radius: 5px;
	margin:0px auto 10px auto;
}

.ess-day-lunch-name {
	margin:0px;
}

.ess-period-parent-container {
	display:grid;
	grid-template-columns: 24px 1fr;
	width: 100%;
	max-width: fit-content;
	background-color:#fff;
	margin:10px 0px 0px 0px;
	border-radius: 8px;
	border:2px solid #ddd;
	background-color: #f5f7fa;
}

.ess-period-inner-container-parent {
	position: relative;
	padding: 14px 30px 0px 15px;
}

.ess-period-inner-container {
	position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 90px;
    align-items: center;
    padding: 0px 0px 0px 0px;
	column-gap:26px;
}

.ess-period-info-outer-container {
	position: relative;
	height:100%;
}

.ess-period-info-inner-container {
	height: 70px;
    display: grid;
    /* align-items: center; */
}

.ess-period-info-label {
	font-size: 16px;
	color:#8e44ad;
}

.ess-time-select-container {
	display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 6px;
    text-align: left;
    margin: 8px 0px 0px 0px;
    align-items: center;
    height: min-content;
}

.ess-time-select-container .time-select-input {
	border:none !important;
}

.ess-time-select-container .time-select-input::-webkit-datetime-edit-hour-field:focus,
.ess-time-select-container .time-select-input::-webkit-datetime-edit-minute-field:focus,
.ess-time-select-container .time-select-input::-webkit-datetime-edit-second-field:focus,
.ess-time-select-container .time-select-input::-webkit-datetime-edit-ampm-field:focus {
  background-color: #e4d2ba;
}

.ess-dropdown-outer-container {
	height:100%;
	/* min-width:100px; */
}

.ess-dropdown-outer-container > .dropdown-container {
	height:100%;
	border: 2px solid #efefef;
	background-color: #fff;
	min-width: 100px;
}

.ess-remove-schedule-period {
	position: absolute;
	top:3px;
	right:6px;
}

.ess-include-in-match-checkmark {
	width: fit-content;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 10px;
    padding: 0px 5px;
	font-size: 14px;
	margin:0px 0px 10px 0px;
}

.ess-droppable-inner-container {
	position: relative;
    display: grid;
    /* height: 100%; */
    height: 70px;
    grid-template-columns: 1fr;
	align-items: center;
    text-align: center;
    border-radius: 5px;
	cursor:default !important;
}

.ess-droppable-no-course-period {
	background-color: #efefef;
    font-size: 12px;
    border: 2px dashed #ddd;
}

.ess-remove-course-period {
	position: absolute;
	top:5px;
	right:5px;
	font-size: 12px;
	cursor: pointer;
}

.ess-period-name-container {
	position: relative;
	display: inline-block;
}

.schedule-setup-edit-schedule-period-name {
	position: absolute;
    top: 0px;
    right: -25px;
    font-size: 12px;
    cursor: pointer;
}

.schedule-setup-edit-schedule-period-name:hover {
	color:#34495e;
}

.schedule-setup-course-period-dots {
	padding:0px 3px;
}

.schedule-setup-course-period-name {
	position: relative;
    display: grid;
    height: 100%;
    align-items: center;
}

.schedule-setup-course-period-name-input-container {
	width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.schedule-setup-course-period-name-input {
	display: block;
    height: 26px;
    line-height: 26px;
    width: 90%;
    border: 2px solid #efefef;
    border-radius: 3px;
    text-align: center;
    margin: 3px auto;
}

.schedule-setup-edit-course-period-name {
	position:absolute;
	bottom:5px;
	right:5px;
	font-size: 12px;
	cursor: pointer;
}

.schedule-setup-remove-course-period-name {
	position:absolute;
	top:5px;
	right:5px;
	font-size: 12px;
	cursor: pointer;
}

.ess-edit-icon-container {
	display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin: 2px 0px 0px 0px;
}

.ess-edit-icon {
	display: inline-block;
	margin:3px;
	color:#efefef;
	cursor: pointer;
}

.ess-edit-icon:hover {
	color:#fff;
}

.schedule-setup-period-name-input {
	width: 200px;
    height: 30px;
    border: 2px solid #efefef;
    border-radius: 4px;
    margin: -5px 0px 0px 0px;
    padding: 2px 0px 2px 8px;
}

.ess-cancel-edit-schedule-period {
	position: absolute;
    top: 4px;
    right: 34px;
    color: #dfdfdf;
    cursor: pointer;
}

.ess-cancel-edit-schedule-period:hover {
	color:#c0392b;
}

.ess-confirm-edit-schedule-period {
	position: absolute;
    top: 4px;
    right: 6px;
    color: #dfdfdf;
    cursor: pointer;
}

.ess-confirm-edit-schedule-period:hover {
	color:#27ae60;
}

#schedule-structure-edit-error {
	width:600px;
	margin:40px auto -25px auto;
}

.sortable-placeholder {
	background-color: #ffeaa7;
	height:100px;
	margin:10px 0px 10px 0px;
	border-radius: 8px;
}

.ess-draggable-course-periods-parent {
	grid-auto-flow: column;
	display: grid;
	grid-template-columns: repeat(auto-fill, 90px);
	grid-template-rows: repeat(8, auto);
	gap: 20px;
}

.course-period-draggable {
	width:90px;
	min-height: 70px;
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	text-align: center;
	border-radius: 5px;
}

.ess-schedule-lunch {
	position: relative;
    max-width: 90px;
    padding: 5px 10px;
    font-size: 12px;
    margin: -18px 0 10px auto;
    border-radius: 5px;
    font-weight: 700;
}

.ess-remove-lunch {
	position:absolute;
	top:7px;
	right:7px;
	cursor: pointer;
}

.ess-lunch-name-container {
	position: relative;
	display: inline-block;
}

.ess-cancel-edit-lunch-name {
	position: absolute;
    top: 8px;
    right: 34px;
    color: #dfdfdf;
    cursor: pointer;
}

.ess-cancel-edit-lunch-name:hover {
	color:#c0392b;
}

.ess-confirm-edit-lunch-name {
	position: absolute;
    top: 8px;
    right: 6px;
    color: #dfdfdf;
    cursor: pointer;
}

.ess-confirm-edit-lunch-name:hover {
	color:#27ae60;
}













