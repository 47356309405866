#school-set-up-container {
	min-width:350px;
	max-width: 400px;
	text-align:left;
	margin:0px 0px 20px 0px;
	border:2px solid #efefef;
	border-radius: 8px;
	padding:10px 20px 30px 20px;
	background-color:#fff;
	box-shadow: 0 0 5px rgba(110,103,86,.15);
	opacity: 0.0;
	animation: fadeIn ease 1s forwards;
	-webkit-animation: fadeIn ease 1s forwards;
}

.school-set-up-label {
	margin:0px;
	padding:0px;
	font-size: 13px;
	color:#a9a9a9;
	margin:15px 0px 0px 0px;
}

.school-set-up-input {
	width:100%;
	height:40px;
	line-height:40px;
	font-size:16px;
	padding:0px 0px 0px 7px;
	border:1px solid #dfdfdf;
	border-radius: 5px;
}
/****************************/
/***** SCHOOL INFO CSS ******/
/****************************/

#school-info-main-container {
	width:70%;
	min-width:950px;
	max-width:1200px;
	margin:20px auto 30px auto;
	text-align:center
}

#school-code-container {
	position: relative;
}

.school-set-up-info-icon {
	font-size: 13px;
    margin: 0px 0px 0px 10px;
}

.school-code-explanation-popup {
	max-width: 280px;
    top: 30px;
    left: 0px;
    padding: 0px 20px 10px 20px;
    border-radius: 10px;
    margin: 0;
}

.school-code-explanation-cancel {
	position: absolute;
    font-size: 16px;
    top: 8px;
    right: 10px;
}

.school-code-explanation-header {
	margin:25px 0px 0px 0px;
}

.school-code-explanation-popup-icon {
	font-size: 20px;
	margin: 0px 10px 0px 0px;
}

.search_categories {
  font-size: 16px;
  padding: 10px 8px 10px 0px;
  margin: 0px 0px 15px 0px;
  background: #fafafa;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.search_categories:focus-within {
	border:2px solid #2980b9;
}

.search_categories .select {
  width: 120%;
  background: url("../../images/expand-arrow-green.png") no-repeat;
  background-size: 15px 15px;
  background-position: 80% center;
}

.search_categories .select select {
  background: transparent;
  line-height: 1;
  border: 0;
  padding: 0px 0px 0px 5px;
  border-radius: 0;
  width: 120%;
  position: relative;
  z-index: 10;
  font-size: 1em;
  cursor: pointer;
}

.search_categories .select select:focus {
  border: none;
  outline: none;
}

#submit-school-info {
	margin:30px 0px 0px 0px;
}

@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}


/********************************/
/*****SCHOOL SETTINGS CSS *******/
/********************************/

#school-settings-container {
	min-width:400px;
	max-width: 450px;
	text-align:left;
	margin:0px 0px 20px 0px;
	border:2px solid #efefef;
	border-radius: 8px;
	padding:10px 20px 30px 20px;
	background-color:#fff;
	box-shadow: 0 0 5px rgba(110,103,86,.15);
}

.school-settings-page-counter-container {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: baseline;
	column-gap: 10px;
	margin:0px 0px 15px 0px;
}

.school-settings-page-counter-current {
	font-size: 24px;
	color:#000;
	border-bottom: 4px solid #3498db;
}

.school-settings-question-page {
	display: none;
	margin:0px 0px 0px 0px;
}

.school-settings-question-container {
	margin:30px 0px 50px 0px;
}

.school-settings-dropdown-container {
	display: grid;
	grid-template-columns: 100px 1fr;
	column-gap: 20px;
	align-items: center;
}

.school-settings-dropdown-container > .dropdown-container {
	padding:12px 10px;
}

.school-settings-dropdown-container > .dropdown-container > .dropdown-display {
	font-size: 18px;
}

.school-settings-question-page.school-settings-current-page {
	display: block !important;
}

.school-settings-question {
	margin:0px 0px 30px 0px;
}